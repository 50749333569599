<template>
  <section id="banner" class="d-flex" v-bind:style="{ maxHeight:'500px', backgroundImage: `url(${publicPath}assets/${data.portfolioName}/banner.jpg)` }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>{{ data.json['title'] }}</h1>
          <h4>{{ data.json['subtitle'] }}</h4>
          <div class="small-line-break my-6"></div>
          <p>{{ data.json['paragraph'] }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Banner');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        portfolioName: portfolioName
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  section {
    height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
    }
  }

  .container {
    margin: auto;
    color: $primary-text-color;
  }
</style>
